<template>
  <div :class="propsClassName">

    <div class="input-group">
      <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-magnifying-glass"></i></span>
      <input type="text" class="form-control" v-model="filterDest"
      :placeholder="isFrom ? $t('sabre.search-panel.where-flying-from'): $t('sabre.search-panel.where-flying-to')">
    </div>
    <div class="fromwhered_chackbox" v-if="previousDestinations && previousDestinations.length">
      <h4> {{$t("sabre.search-panel.last-searches")}}</h4>
      <div class="row g-3 row-cols-2">
        <div class="form-check col" v-for="(dest, inx) in previousDestinations" :key="inx" @click="() => selectDest(dest.code)">
          <input class="form-check-input" type="checkbox" :id="`prevCheckbox${inx}`"
           :checked="chosenDest.includes(dest.code)">
          <label class="form-check-label" :for="`prevCheckbox${inx}`">{{isLangCodeEn ? dest.name : dest.name_Loc.content}}</label>
        </div>
      </div>
    </div>
    <div class="fromwhered_chackbox" v-if="topDestinations && topDestinations.length">
      <h4>{{$t("sabre.search-panel.top-destinations")}} </h4>
      <div class="row g-3 row-cols-2">
        <div class="form-check col" v-for="(dest, inx) in topDestinations" :key="previousDestinations.length+inx" @click="() => selectDest(dest.code)">
          <input class="form-check-input" type="checkbox" :id="`topCheckbox${inx}`"
          :checked="chosenDest.includes(dest.code)">
          <label class="form-check-label" :for="`topCheckbox${inx}`">{{isLangCodeEn ? dest.name : dest.name_Loc.content}}</label>
        </div>
      </div>
    </div>

    <div class="btn-group-area d-flex justify-content-end" v-if="type==='dropdown'">
      <button class="cleanup_btn" @click="() => selectDest()">{{$t("sabre.buttons.clean")}}</button>
      <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-destination',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isFrom: {
      type: Boolean,
      default: false,
    },
    isMultiCity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_DESTINATION_IMAGES',
      dropdownOpenState: 'GET_OPEN_STATE_SEARCH_DROPDOWN',
      chosenStoreDest: 'GET_SABRE_CHOSEN_DESTINATIONS',
      chosenMultiCityStoreDest: 'GET_SABRE_CHOSEN_MULTI_DESTINATIONS',

      categories: 'GET_CATEGORIES',
    }),
    isLangCodeEn() {
      return this.$route.query?.langCode === 'ENG';
    },
    storeKey() {
      const { isMultiCity } = this;
      return (isMultiCity) ? 'prev-multi-dest' : 'prev-search-dest';
    },
    chosenDest() {
      const { isFrom, chosenStoreDest, chosenMultiCityStoreDest, isMultiCity } = this;
      const origin = (isMultiCity) ? chosenMultiCityStoreDest : chosenStoreDest;
      return (isFrom) ? origin.from : origin.to;
    },
    storageData() {
      const { isFrom, storeKey } = this;
      const data = JSON.parse(window.localStorage.getItem(storeKey));
      const list = (isFrom) ? data?.from || [] : data?.to || [];
      if (!list.includes('TLV')) list.push('TLV');
      return list;
    },
    previousDestinations() {
      const { storageData, destinations } = this;
      if (!storageData || !destinations.length) return [];

      return destinations.filter((dest) => storageData.includes(dest.code));
    },
    topDestinations() {
      const { storageData, destinations } = this;
      if (!destinations.length) return [];
      if (!storageData) return destinations;

      return destinations.filter((dest) => !storageData.includes(dest.code));
    },
    destinations() {
      const { destinationList, filterDest } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      const lowerLetter = filterDest.toLowerCase();
      return destinationList?.filter((dest) => (dest.code.toLowerCase().includes(lowerLetter) || dest.name?.toLowerCase().includes(lowerLetter) || dest.name_Loc?.content?.toLowerCase().includes(lowerLetter)))
        .filter((dest) => dest.name) || [];
    },
    fcSupplierDestinations() {
      const { categories } = this;
      if (!categories) return null;

      return categories.filter((cate) => cate.active).find((cate) => cate.code === 'Flight_Only')?.destinations || null;
    },
  },
  watch: {
    dropdownOpenState() {
      // if (!this.dropdownOpenState) this.save();
    },
  },
  data() {
    return {
      filterDest: '',
    };
  },
  methods: {
    selectDest(code) {
      const { isMultiCity } = this;
      const from = this.isFrom;
      const clean = !code;
      this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from, code, clean, isMultiCity });
    },
    save() {
      const storeData = (this.isMultiCity) ? this.chosenMultiCityStoreDest : this.chosenStoreDest;
      this.$emit('finished');
      if (!storeData.from.length && !storeData.to.length) return;

      window.localStorage.setItem(this.storeKey, JSON.stringify(storeData));
    },
  },
};
</script>
